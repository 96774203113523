<script setup lang="ts">
  import type { IBreadcrumb } from '../EastclinicVueApi';

  const props = defineProps<{items:IBreadcrumb[]}>();
  const breadcrumbsSchema = [];
  props.items.map((b) => breadcrumbsSchema.push({ name: b.title, item: b.url }));

  useSchemaOrg([
    defineBreadcrumb({
      itemListElement: breadcrumbsSchema
    })
  ]);

// todo check correct breadcrumbs structure
</script>

<template>
  <div class="breadcrumbs" itemscope="" itemtype="https://schema.org/BreadcrumbList">
    <div
      v-for="(breadcrumb, key) in props.items"
      :key="key"
      itemprop="itemListElement"
      itemscope=""
      itemtype="https://schema.org/ListItem"
    >
      <div v-if="key !== Object.keys(items).length - 1">
        <div class="breadcrumbs__item pointer">
          <nuxt-link :to="breadcrumb.url" item-prop="item">
            <span itemProp="name">{{ breadcrumb.title }}</span>
          </nuxt-link>

          <meta :content=" key+1 +''" itemprop="position">
        </div>
      </div>
      <span v-if="key !== Object.keys(items).length - 1">
        /
      </span>
      <div v-else>
        <div class="breadcrumbs__item">
          <span itemProp="name">{{ breadcrumb.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.breadcrumbs {
  & * {
    display: inline;
  }
  & :not(:last-child) {
    margin-right: $margin-1;
  }
  font-size: 14px;
  color: $text-secondary-color !important;
  gap: 10px;
  margin-bottom: 8px;
  font-weight: 500;
  &__item {
    & a {
      color: $text-secondary-color !important;
    }
    align-items: center;
    gap: 10px;
  }
}
</style>
